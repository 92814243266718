module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/logo/logo_rectangle.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fdc70358fbc5333ed03d868457465f1d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","fr"],"defaultLanguage":"en","siteUrl":"https://www.seccsb.com/","i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","fr"],"defaultNS":"common","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/form-submission","excludeLanguages":["en","fr"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
